import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { BoltIcon, UsersIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import dawgsLogo from './assets/dawgsLogo.png';

const features = [
  {
    name: 'Track Federal Spending',
    description: 'GovDAWGS provides a real-time view into federal spending, allowing taxpayers to see exactly where their money goes.',
    icon: ChartPieIcon,
  },
  {
    name: 'Identify Wasteful Spending',
    description: 'Help identify and highlight potentially wasteful government expenditures.',
    icon: BoltIcon,
  },
  {
    name: 'Promote Accountability',
    description: 'By increasing transparency, GovDAWGS empowers citizens and organizations to demand better use of public funds.',
    icon: UsersIcon,
  },
];

const stats = [
  { label: 'Available Transactions', value: '1 million +' },
  { label: 'Potential waste identified', value: '$100 billion+' },
  { label: 'Users monitoring spending', value: 'TBD!' },
];

export default function LandingPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-gray-900 text-gray-200 font-sans antialiased">
      
      {/* Hero Section */}
      <header className="relative overflow-hidden bg-gradient-to-b from-gray-800 to-gray-700 pt-24 pb-32 sm:pb-40 lg:pb-48">
        <div className="relative z-10 max-w-7xl mx-auto px-6 lg:px-8">
          <div className="text-center">
            <img src={dawgsLogo} alt="GovDAWGS Logo" className="mx-auto mb-4" style={{ width: '30%' }} />
            <h1 className="text-5xl font-extrabold tracking-tight text-white sm:text-6xl">
              GovDAWGS
            </h1>
            <p className="mt-4 max-w-2xl mx-auto text-lg leading-8 text-gray-300">
              Defenders Against Wasteful Government Spending - Uncovering federal waste to protect taxpayer dollars.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <a href="/signup" className="rounded-lg bg-red-600 px-6 py-3 text-sm font-semibold text-white shadow-lg hover:bg-red-500 transition">
                Get Started
              </a>
            
            </div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="bg-gray-800 py-24">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-base font-semibold text-red-600">Our Mission</h2>
          <p className="mt-2 text-4xl font-extrabold tracking-tight text-white sm:text-5xl">Empowering Transparency</p>
          <p className="mt-6 text-lg leading-7 text-gray-300 max-w-3xl mx-auto">
            GovDAWGS is dedicated to exposing wasteful spending in federal budgets. We believe in accountability and the efficient use of taxpayer funds to strengthen trust in our government.
          </p>

          <div className="mt-16 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="text-center">
                <feature.icon className="mx-auto h-12 w-12 text-red-600" aria-hidden="true" />
                <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-100">{feature.name}</h3>
                <p className="mt-2 text-base text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Impact Section */}
      <section className="bg-gray-900 py-24 sm:py-32 lg:py-40">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">Our Impact</h2>
          <p className="mt-6 text-lg text-gray-300 max-w-3xl mx-auto">
            At GovDAWGS, we’re committed to defending against wasteful government spending by bringing transparency and accountability to federal expenses.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-10 md:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.label} className="text-center">
                <dd className="text-5xl font-extrabold tracking-tight text-red-600">{stat.value}</dd>
                <dt className="mt-2 text-base font-medium text-gray-400">{stat.label}</dt>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gray-800 py-24">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white">Join GovDAWGS Today</h2>
          <p className="mt-4 text-lg text-gray-300">
            Help us bring transparency to federal spending. Together, we can hold the government accountable for the responsible use of taxpayer money.
          </p>
          <div className="mt-8">
            <a href="/signup" className="inline-block rounded-lg bg-red-600 px-6 py-3 text-sm font-semibold text-white shadow-lg hover:bg-red-500 transition">
              Get Started
            </a>
          </div>
        </div>
      </section>

      
    </div>
  );
}
