import React, { useState } from 'react';
import { Link } from 'react-router-dom';


export default function AwardsTable({ awards }) {
  const [sortField, setSortField] = useState("Award Amount");
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeOnly, setActiveOnly] = useState(false);

  // Award type mappings
  const awardTypeMappings = {
    contracts: { codes: ['A', 'B', 'C', 'D'], label: 'Contracts' },
    loans: { codes: ['07', '08'], label: 'Loans' },
    idvs: { codes: ['IDV_A', 'IDV_B', 'IDV_B_A', 'IDV_B_B', 'IDV_B_C', 'IDV_C', 'IDV_D', 'IDV_E'], label: 'Indefinite Delivery Vehicles' },
    grants: { codes: ['02', '03', '04', '05'], label: 'Grants' },
    other_financial_assistance: { codes: ['06', '10'], label: 'Other Financial Assistance' },
    direct_payments: { codes: ['09', '11', '-1'], label: 'Direct Payments' }
  };

  // Helper function to check if an award is active
  const isActive = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    return start <= today && today <= end;
  };

  // Helper function to get the status badge
  const getStatusBadge = (startDate, endDate, baseObligationDate) => {
    const today = new Date();
    const end = endDate ? new Date(endDate) : null;
    const obligation = baseObligationDate ? new Date(baseObligationDate) : null;
  
    // Determine the relevant date to use (the later of End Date or Base Obligation Date)
    const relevantDate = end && obligation ? (end > obligation ? end : obligation) : (end || obligation);
  
    // If the relevant date is in the future, mark as Active
    if (relevantDate && relevantDate > today) {
      return (
        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Active
        </span>
      );
    }
  
    // Otherwise, mark as Expired
    return (
      <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
        Expired
      </span>
    );
  };

  // Helper function to dedupe awards based on Award ID
  const dedupeAwards = (awards) => {
    const seenAwardIds = new Set();
    return awards.filter((award) => {
      if (seenAwardIds.has(award["Award ID"])) {
        return false;
      }
      seenAwardIds.add(award["Award ID"]);
      return true;
    });
  };

  // Helper function to get award type code and tooltip
  const getAwardTypeInfo = (awardType) => {
    for (const [group, { codes, label }] of Object.entries(awardTypeMappings)) {
      if (codes.includes(awardType)) {
        return { code: codes[0], label };
      }
    }
    console.warn(`Unmapped award type: ${awardType}`); // Debugging line
    return { code: "N/A", label: "Unknown" };
  };

  // Deduplicate awards before sorting and filtering
  const uniqueAwards = dedupeAwards(awards);

  // Handle sorting by selected field and order
  const sortedAwards = [...uniqueAwards].sort((a, b) => {
    let fieldA, fieldB;

    if (sortField === "Status") {
      const isActiveA = isActive(a["Start Date"], a["End Date"]);
      const isActiveB = isActive(b["Start Date"], b["End Date"]);
      fieldA = isActiveA ? 1 : 0;
      fieldB = isActiveB ? 1 : 0;
    } else {
      fieldA = a[sortField];
      fieldB = b[sortField];
    }
    
    if (sortOrder === "asc") {
      return fieldA > fieldB ? 1 : -1;
    } else {
      return fieldA < fieldB ? 1 : -1;
    }
  });

  // Filter to show only active awards if activeOnly is enabled
  const displayedAwards = activeOnly
    ? sortedAwards.filter(award => isActive(award["Start Date"], award["End Date"]))
    : sortedAwards;

  const handleSortChange = (e) => {
    const [field, order] = e.target.value.split("-");
    setSortField(field);
    setSortOrder(order);
  };

  const toggleActiveOnly = () => {
    setActiveOnly(!activeOnly);
  };

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">Awards</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of awards related to the recipient, including award amount, agency, and description.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center space-x-4">
          <label htmlFor="sort" className="sr-only">Sort by</label>
          <select
            id="sort"
            name="sort"
            className="block rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-700 border border-gray-300"
            onChange={handleSortChange}
            defaultValue="Award Amount-asc"
          >
            <option value="Award Amount-asc">Amount: Low to High</option>
            <option value="Award Amount-desc">Amount: High to Low</option>
            <option value="Awarding Agency-asc">Agency: A to Z</option>
            <option value="Awarding Agency-desc">Agency: Z to A</option>
            <option value="Award Type-asc">Award Type: A to Z</option>
            <option value="Award Type-desc">Award Type: Z to A</option>
            <option value="Status-desc">Status: Active to Expired</option>
            <option value="Status-asc">Status: Expired to Active</option>
          </select>
          <button
            type="button"
            className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${activeOnly ? "bg-blue-100 text-blue-700 ring-1 ring-blue-500/20" : "bg-gray-100 text-gray-700"}`}
            onClick={toggleActiveOnly}
          >
            Active Only
          </button>
        </div>
      </div>
      
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Awarding Agency
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Award Amount
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    End
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Explore</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {displayedAwards.map((award) => {
                  const { code, label } = getAwardTypeInfo(award["Award Type"]);
                  return (
                    <tr key={award["Award ID"]}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {award["Awarding Agency"] ? `${award["Awarding Agency"].substring(0, 30)}...` : "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${award["Award Amount"].toLocaleString()}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {(() => {
    const endDate = award["End Date"] ? new Date(award["End Date"]) : null;
    const obligationDate = award["Base Obligation Date"] ? new Date(award["Base Obligation Date"]) : null;
    
    const laterDate = endDate && obligationDate ? (endDate > obligationDate ? endDate : obligationDate) : (endDate || obligationDate);

    return laterDate ? laterDate.toLocaleDateString() : "N/A";
  })()}
</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        {getStatusBadge(award["Start Date"], award["End Date"])}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {award.Description ? `${award.Description.substring(0, 50)}...` : "N/A"}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <Link to={`/award/${award["generated_internal_id"]}`} className="text-indigo-600 hover:text-indigo-900">
  Explore
</Link>
</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
