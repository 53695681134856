import { useEffect, useState } from 'react';
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  FunnelIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import debounce from 'lodash.debounce';
import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import AwardVisualization from './AwardVisualization'; // Import the visualization component
 

export default function AwardSearch() {
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [recipientInput, setRecipientInput] = useState('');
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(10000000000000000);
  const [dateRange, setDateRange] = useState('Any time');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [recipientLocation, setRecipientLocation] = useState('');
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [highRiskCount, setHighRiskCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState('Recipient Name');
  const [sortOrder, setSortOrder] = useState('desc');
  const [uniqueAgencies, setUniqueAgencies] = useState([]);
  const [uniqueRecipients, setUniqueRecipients] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [viewMode, setViewMode] = useState('table'); // Track view mode: 'table' or 'chart'
  const [appliedKeywords, setAppliedKeywords] = useState([]);
  const [appliedRecipients, setAppliedRecipients] = useState([]);
  const [visualizeOpen, setVisualizeOpen] = useState(false); // State to control the visualization dialog

  const handleOpenVisualization = () => {
    setVisualizeOpen(true);
  };

  const handleCloseVisualization = () => {
    setVisualizeOpen(false);
  };


  const navigate = useNavigate();

  const handleSearch = debounce(async () => {
    setLoading(true);
    setResultCount(0); // Reset result count at the beginning of a new search
    let aggregatedResults = [];
    setAppliedKeywords([...keywords]);
    setAppliedRecipients([...recipients]);
    setTotalAmount(0);
    setHighRiskCount(0);

    const fetchResultsByGroup = async (awardTypeCodes) => {
        let groupResults = [];
        let page = 1;
        let hasNextPage = true;

        const now = new Date();
        let rangeStart;

        switch (dateRange) {
            case 'Past month':
                rangeStart = new Date(now);
                rangeStart.setMonth(rangeStart.getMonth() - 1);
                break;
            case 'Past 6 months':
                rangeStart = new Date(now);
                rangeStart.setMonth(rangeStart.getMonth() - 6);
                break;
            case 'Past year':
                rangeStart = new Date(now);
                rangeStart.setFullYear(rangeStart.getFullYear() - 1);
                break;
            case 'Past 5 years':
                rangeStart = new Date(now);
                rangeStart.setFullYear(rangeStart.getFullYear() - 5);
                break;
            default:
                break;
        }

        const filters = {
            keywords: keywords.length > 0 ? keywords : undefined,
            award_type_codes: awardTypeCodes,
            award_amounts: [{ lower_bound: minAmount, upper_bound: maxAmount }],
            subawards: false,
        };

        if (rangeStart) {
            filters.time_period = [
                {
                    start_date: rangeStart.toISOString().split('T')[0],
                    end_date: now.toISOString().split('T')[0],
                },
            ];
        }

        if (recipientLocation) filters.recipient_scope = recipientLocation;
        if (recipients.length > 0) filters.recipient_search_text = recipients;

        while (hasNextPage) {
            const body = JSON.stringify({
                filters,
                fields: [
                    'Award ID', 'Recipient Name', 'Action Date', 'Award Amount',
                    'Awarding Agency', 'Award Type', 'Description', 'Start Date', 'End Date', 'generated_internal_id'
                ],
                page,
                limit: 100,
                sort: 'Recipient Name',
                order: sortOrder,
            });

            try {
                const response = await fetch('https://api.usaspending.gov/api/v2/search/spending_by_award/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body,
                });
                const data = await response.json();

                if (data.results && data.results.length > 0) {
                    const newResults = data.results.map(standardizeAwardData);

                    // Avoid duplicates by checking against aggregatedResults
                    groupResults = [...groupResults, ...newResults.filter(newResult => 
                        !aggregatedResults.some(existingResult => existingResult.awardId === newResult.awardId)
                    )];
                    
                    aggregatedResults = [...aggregatedResults, ...groupResults];

                    // Update result count dynamically
                    setResultCount((prevCount) => prevCount + newResults.length);

                    page = data.page_metadata.next || page + 1;
                    hasNextPage = data.page_metadata.hasNext;
                } else {
                    hasNextPage = false;
                }
            } catch (error) {
                console.error('Error fetching award search results:', error);
                hasNextPage = false;
            }
        }
        return groupResults;
    };

    const awardTypeCodeGroups = {
        contracts: ['A', 'B', 'C', 'D'],
        loans: ['07', '08'],
        idvs: ['IDV_A', 'IDV_B', 'IDV_B_A', 'IDV_B_B', 'IDV_B_C', 'IDV_C', 'IDV_D', 'IDV_E'],
        grants: ['02', '03', '04', '05'],
        other_financial_assistance: ['06', '10'],
        direct_payments: ['09', '11', '-1']
    };

    const resultsByGroup = await Promise.all(
        Object.values(awardTypeCodeGroups).map(fetchResultsByGroup)
    );

    // Consolidate results after removing duplicates
    aggregatedResults = Array.from(new Set(aggregatedResults.map(result => result.awardId)))
        .map(id => aggregatedResults.find(result => result.awardId === id));

    const total = aggregatedResults.reduce((sum, result) => sum + Number(result.awardAmount || 0), 0);
    const highRisk = aggregatedResults.filter(result => Number(result.awardAmount) > 250000000).length;

    const agencies = [...new Set(aggregatedResults.map(result => result.agency))];
    const recipientsList = [...new Set(aggregatedResults.map(result => result.recipientName))];

    setUniqueAgencies(agencies);
    setUniqueRecipients(recipientsList);
    setTotalAmount(total);
    setHighRiskCount(highRisk);
    setResults(aggregatedResults);
    setFilteredResults(aggregatedResults);
    setLoading(false);
}, 500);

  

  function standardizeAwardData(result) {
    return {
      awardId: result['Award ID'] || result['Sub-Award ID'],
      recipientName: result['Recipient Name'] || result['Sub-Awardee Name'],
      agency: result['Awarding Agency'],
      description: result['Description'],
      generatedInternalId: result['generated_internal_id'],
      awardType: result['Award Type'] || result['Sub-Award Type'],
      startDate: result['Start Date'] || result['Issued Date'] || result['Action Date'] || null,
      endDate: result['End Date'] || result['Last Date to Order'] || result['Sub-Award Date'] || null,
      awardAmount: result['Award Amount'] || result['Loan Value'] || result['Sub-Award Amount'] || 0,
      totalOutlays: result['Total Outlays'] || result['Subsidy Cost'] || 0,
    };
  }

  const handleSearchClick = () => {
    setSelectedAgency('');
    setSelectedRecipient('');
    handleSearch();
  };


  useEffect(() => {
    // Filter results based on selected agency and recipient
    let filtered = results.filter(result =>
      (selectedAgency === '' || result.agency === selectedAgency) &&
      (selectedRecipient === '' || result.recipientName === selectedRecipient)
    );
  
    // Apply sorting logic based on sortField and sortOrder
    if (sortField && sortOrder) {
      filtered = filtered.sort((a, b) => {
        const multiplier = sortOrder === 'asc' ? 1 : -1;
  
        if (sortField === 'startDate') {
          return (new Date(a[sortField]) - new Date(b[sortField])) * multiplier;
        } else {
          return (Number(a[sortField]) - Number(b[sortField])) * multiplier;
        }
      });
    }
  
    // Update filteredResults and recalculate Quick Insights data based on sorted and filtered results
    setFilteredResults(filtered);
    setResultCount(filtered.length);
  
    // Calculate total amount and high-risk count based on sorted and filtered results
    const totalAmount = filtered.reduce((sum, result) => sum + Number(result.awardAmount || 0), 0);
    const highRiskCount = filtered.filter(result => Number(result.awardAmount) > 250000000).length;
  
    setTotalAmount(totalAmount);
    setHighRiskCount(highRiskCount);
  }, [selectedAgency, selectedRecipient, sortField, sortOrder, results]);
  
  
  

  const handleSortChange = (e) => {
    const value = e.target.value;
  
    if (value === "startDate (desc)") {
      setSortField("startDate");
      setSortOrder("desc");
    } else if (value === "startDate (asc)") {
      setSortField("startDate");
      setSortOrder("asc");
    } else if (value === "awardAmount (desc)") {
      setSortField("awardAmount");
      setSortOrder("desc");
    } else if (value === "awardAmount (asc)") {
      setSortField("awardAmount");
      setSortOrder("asc");
    }
  
    console.log("Updated Sort Field:", sortField, "Updated Sort Order:", sortOrder);
  };
  
  
  
  const handleOpenAward = (generatedInternalId) => {
    if (generatedInternalId) {
      window.open(`/award/${generatedInternalId}`, '_blank');
    }
  };

  const QuickInsightsPanel = () => (
    <div className="quick-insights-panel flex flex-col sm:flex-row gap-4 my-4">
      <div className="bg-gray-800 p-4 rounded shadow text-center flex-1 min-w-[150px]">
        <p className="text-sm text-gray-400">Total Amount</p>
        <p className="text-lg font-semibold text-white">
          ${totalAmount.toLocaleString()}
        </p>
      </div>
      <div className="bg-gray-800 p-4 rounded shadow text-center flex-1 min-w-[150px]">
        <p className="text-sm text-gray-400">High-Risk Transactions 250M+</p>
        <p className="text-lg font-semibold text-red-500">{highRiskCount}</p>
      </div>
    </div>
  );

  const renderCardView = () => (
    <div className="sm:hidden grid gap-4">
      {filteredResults.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((result, index) => (
        <div key={index} className="bg-gray-800 p-4 rounded-lg shadow">
          <p className="text-sm text-gray-400 mb-1">{result.startDate}</p>
          <p className="text-lg font-semibold text-blue-400">{result.recipientName}</p>
          <p className="text-sm text-gray-300">{result.agency}</p>
          <p className="text-lg font-semibold text-white mt-2">${Number(result.awardAmount).toLocaleString()}</p>
          <button onClick={() => handleOpenAward(result.generatedInternalId)}
            className="mt-3 text-sm text-indigo-300 underline">
            Explore
          </button>
        </div>
      ))}
    </div>
  );
  

  const totalPages = Math.ceil(filteredResults.length / itemsPerPage);
  const paginatedResults = filteredResults.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

  return (
    <div className="px-4 sm:px-6 lg:px-8 text-white overflow-x-hidden">
      <div className="text-center">
        <h1 className="text-2xl font-semibold">Award Search</h1>
        <p className="mt-2 text-sm text-gray-400">Search government awards with filters and pagination.</p>
      </div>

      <div className="mt-6 space-y-4">
        <div className="flex items-center bg-gray-800 rounded p-2 border border-gray-600">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Enter keywords and press Tab or comma"
            value={keywordInput}
            onChange={(e) => setKeywordInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === ',' || e.key === 'Tab' || e.key === 'Enter') {
                e.preventDefault();
                if (keywordInput.trim()) {
                  setKeywords([...keywords, keywordInput.trim()]);
                  setKeywordInput('');
                }
              }
            }}
            className="flex-grow bg-transparent focus:outline-none text-white"
          />
          <div className="flex flex-wrap gap-2 ml-2">
            {keywords.map((keyword, index) => (
              <div key={index} className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded">
                {keyword}
                <button onClick={() => setKeywords(keywords.filter((k) => k !== keyword))} className="ml-1">
                  <XMarkIcon className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
          <button onClick={() => setIsFilterModalOpen(true)} className="p-2 bg-gray-700 rounded hover:bg-gray-600 text-gray-300 ml-2">
            <FunnelIcon className="h-5 w-5" />
          </button>
        </div>

        {/* Date Range and Custom Range Picker */}
        <div className="flex space-x-2 items-center">
          <select
            value={dateRange}
            onChange={(e) => {
              setDateRange(e.target.value);
              setShowCustomDateRange(e.target.value === 'Custom range');
            }}
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full text-sm"
          >
            <option value="Any time">Any time</option>
            <option value="Past month">Past month</option>
            <option value="Past 6 months">Past 6 months</option>
            <option value="Past year">Past year</option>
            <option value="Past 5 years">Past 5 years</option>
            <option value="Custom range">Custom range...</option>
          </select>
          {showCustomDateRange && (
            <div className="flex space-x-2">
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="Start date" className="p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm w-full" />
              <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} placeholderText="End date" className="p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm w-full" />
            </div>
          )}
        </div>

        {/* Search Button */}
        <button onClick={handleSearchClick} className="mt-4 w-full bg-blue-600 p-2 rounded text-white hover:bg-blue-700" disabled={loading}>
          {loading ? 'Loading...' : 'Search'}
        </button>
      </div>

{/* Results Summary and Search Chips */}
<div className="my-4">
  <p className="text-sm font-semibold text-gray-400 mb-2 text-center sm:text-left">
    {resultCount} results found
  </p>

  {/* Applied Keywords Chips */}
  {appliedKeywords.length > 0 && (
    <div className="flex flex-wrap gap-2 mb-2 justify-center sm:justify-start">
      {appliedKeywords.map((keyword, index) => (
        <div key={index} className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded">
          {keyword}
        </div>
      ))}
    </div>
  )}

  {/* Applied Recipients Chips */}
  {appliedRecipients.length > 0 && (
    <div className="flex flex-wrap gap-2 mb-2 justify-center sm:justify-start">
      {appliedRecipients.map((recipient, index) => (
        <div key={index} className="flex items-center px-2 py-1 bg-green-500 text-white text-xs rounded">
          {recipient}
        </div>
      ))}
    </div>
  )}
</div>



      {/* Filter Options */}
      {results.length > 0 && (
        <div className="mt-4 flex flex-col sm:flex-row gap-4 items-center">
          <select
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full sm:w-1/3 text-sm"
            value={selectedAgency}
            onChange={(e) => setSelectedAgency(e.target.value)} // Update selectedAgency on change
          >
            <option value="">Awarding Agency</option>
            {uniqueAgencies.map((agency, index) => (
              <option key={index} value={agency}>{agency}</option>
            ))}
          </select>
          <select
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full sm:w-1/3 text-sm"
            value={selectedRecipient}
            onChange={(e) => setSelectedRecipient(e.target.value)} // Update selectedRecipient on change
          >
            <option value="">Recipient</option>
            {uniqueRecipients.map((recipient, index) => (
              <option key={index} value={recipient}>{recipient}</option>
            ))}
          </select>
          <select
  value={`${sortField} (${sortOrder === "desc" ? "desc" : "asc"})`}
  onChange={handleSortChange}
  className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full sm:w-1/3 text-sm"
>
  <option value="startDate (desc)">Action Date (newest to oldest)</option>
  <option value="startDate (asc)">Action Date (oldest to newest)</option>
  <option value="awardAmount (desc)">Award Amount (highest to lowest)</option>
  <option value="awardAmount (asc)">Award Amount (lowest to highest)</option>
</select>

        </div>
      )}


      {/* Filter Modal */}
      <Dialog open={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-md w-full rounded bg-gray-800 p-6 text-white">
            <Dialog.Title className="text-xl font-semibold mb-4">Additional Filters</Dialog.Title>
            <div className="space-y-4">
              <div>
                <input
                  type="text"
                  placeholder="Enter recipient names and press Tab or comma"
                  value={recipientInput}
                  onChange={(e) => setRecipientInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === ',' || e.key === 'Tab' || e.key === 'Enter') {
                      e.preventDefault();
                      if (recipientInput.trim()) {
                        setRecipients([...recipients, recipientInput.trim()]);
                        setRecipientInput('');
                      }
                    }
                  }}
                  className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm"
                />
                <div className="flex flex-wrap gap-2 mt-2">
                  {recipients.map((recipient, index) => (
                    <div key={index} className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded">
                      {recipient}
                      <button onClick={() => setRecipients(recipients.filter((r) => r !== recipient))} className="ml-1">
                        <XMarkIcon className="h-3 w-3" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <select
                value={recipientLocation}
                onChange={(e) => setRecipientLocation(e.target.value)}
                className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm"
              >
                <option value="">Location Scope</option>
                <option value="domestic">Domestic</option>
                <option value="foreign">Foreign</option>
              </select>
              <div className="flex space-x-2">
                <input
                  type="number"
                  placeholder="Min Amount"
                  value={minAmount}
                  onChange={(e) => setMinAmount(Number(e.target.value))}
                  className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm"
                />
                <input
                  type="number"
                  placeholder="Max Amount"
                  value={maxAmount}
                  onChange={(e) => setMaxAmount(Number(e.target.value))}
                  className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm"
                />
              </div>
            </div>
            <button onClick={() => setIsFilterModalOpen(false)} className="mt-6 w-full bg-blue-600 p-2 rounded text-white hover:bg-blue-700">
              Apply Filters
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>

      

      {/* Quick Insights Panel */}
      {results.length > 0 && <QuickInsightsPanel />}

     {/* Visualize Button */}
     {filteredResults.length > 0 && (
        <button
          onClick={handleOpenVisualization}
          className="mt-4 w-full bg-blue-600 p-2 rounded text-white hover:bg-blue-700"
        >
          Visualize
        </button>
      )}


      
      {/* Results Table */}
      {loading ? (
        <div className="mt-4 overflow-hidden rounded-lg shadow bg-gray-800 text-white">
          <table className="min-w-full divide-y divide-gray-700">
            <thead>
              <tr>{['Award ID', 'Recipient', 'Agency', 'Amount', 'Date'].map((header, index) => (
                <th key={index} className="px-3 py-3.5 text-left text-sm font-semibold text-gray-400">{header}</th>
              ))}</tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {[...Array(10)].map((_, index) => (
                <tr key={index} className="animate-pulse">
                  <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
                  <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
                  <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
                  <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
                  <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : filteredResults.length > 0 ? (
        <>
          <div className="hidden sm:block mt-4 overflow-hidden rounded-lg shadow bg-gray-800 text-white">
            <table className="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>{['Date', 'Agency', 'Recipient', 'Amount', 'Explore'].map((header, index) => (
                  <th key={index} className="px-3 py-3.5 text-left text-sm font-semibold text-gray-400">{header}</th>
                ))}</tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredResults.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((result, index) => (
                  <tr key={index} className="hover:bg-gray-700 transition">
                    <td className="px-3 py-4 text-sm text-gray-300">{result.startDate}</td>
                    <td className="px-3 py-4 text-sm text-gray-300">{result.agency}</td>
                    <td className="px-3 py-4 text-sm text-blue-400">{result.recipientName}</td>
                    <td className="px-3 py-4 text-sm text-gray-300 flex items-center">
                      {Number(result.awardAmount) > 250000000 && <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-1" />}
                      ${Number(result.awardAmount).toLocaleString()}
                    </td>
                    <td className="pl-3">
                      <button onClick={() => handleOpenAward(result.generatedInternalId)} className="order-first flex-none rounded-full bg-indigo-400/10 px-3 py-2 text-xs font-medium text-indigo-300 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                        Explore
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {renderCardView()}
        </>
      ) : (
        !loading && <p className="mt-4 text-center text-gray-400">No results found. Try adjusting your search criteria.</p>
      )}

      {/* Pagination Controls */}
      {filteredResults.length > 0 && (
        <div className="mt-4 flex items-center justify-between">
          <button onClick={handlePreviousPage} disabled={currentPage === 1 || loading}
            className="rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-gray-200 disabled:opacity-50">
            Previous
          </button>
          <span className="text-sm font-semibold text-gray-300">Page {currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages || loading}
            className="rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-gray-200 disabled:opacity-50">
            Next
          </button>
        </div>
      )}

{/* Award Visualization Dialog */}
<AwardVisualization
        isOpen={visualizeOpen}
        onClose={handleCloseVisualization}
        filteredResults={filteredResults} // Pass filtered results as a prop
      />
      
    </div> 

    
  );
}
