import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

export default function FlaggedAwardsPage() {
  const [flaggedAwards, setFlaggedAwards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  // Fetch the user ID on mount
  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
      } else if (user) {
        setUserId(user.id);
      }
    };
    fetchUser();
  }, []);

  // Fetch flagged awards
  useEffect(() => {
    const fetchFlaggedAwards = async () => {
      if (!userId) return;

      setLoading(true);
      const { data: flaggedData, error } = await supabase
        .from('flagged_awards')
        .select('generated_internal_id, recipient_name, awarding_agency, obligation, reason, explanation')
        .eq('user_id', userId);

      if (error) {
        console.error('Error fetching flagged awards:', error);
      } else {
        setFlaggedAwards(flaggedData);
      }
      setLoading(false);
    };

    fetchFlaggedAwards();
  }, [userId]);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="container mx-auto p-4 space-y-4">
      <h1 className="text-2xl font-semibold text-white">Awards Flagged for Cutting</h1>
      {flaggedAwards.length === 0 ? (
        <p className="text-gray-400">You have not flagged any awards for cutting.</p>
      ) : (
        <>
          {/* Table view for larger screens */}
          <div className="hidden md:block bg-gray-800 rounded-lg p-6">
            <table className="min-w-full bg-gray-800">
              <thead>
                <tr className="text-gray-400 border-b border-gray-600">
                  <th className="py-2 px-3 text-left text-sm font-semibold">Recipient</th>
                  <th className="py-2 px-3 text-left text-sm font-semibold">Agency</th>
                  <th className="py-2 px-3 text-left text-sm font-semibold">Total Obligations</th>
                  <th className="py-2 px-3 text-left text-sm font-semibold">Reason</th>
                  <th className="py-2 px-3 text-left text-sm font-semibold">Explanation</th>
                  <th className="py-2 px-3 text-left text-sm font-semibold">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-300">
                {flaggedAwards.map((award) => (
                  <tr key={award.generated_internal_id} className="border-b border-gray-700">
                    <td className="py-2 px-3">{award.recipient_name || 'N/A'}</td>
                    <td className="py-2 px-3">{award.awarding_agency || 'N/A'}</td>
                    <td className="py-2 px-3">
                      ${award.obligation ? award.obligation.toLocaleString() : 'N/A'}
                    </td>
                    <td className="py-2 px-3">{award.reason}</td>
                    <td className="py-2 px-3">{award.explanation}</td>
                    <td className="py-2 px-3">
                      <button
                        onClick={() => navigate(`/award/${award.generated_internal_id}`)}
                        className="text-blue-500 hover:underline"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Card view for mobile screens */}
          <div className="md:hidden space-y-4">
            {flaggedAwards.map((award) => (
              <div key={award.generated_internal_id} className="bg-gray-800 rounded-lg p-4 space-y-2">
                <div className="text-sm text-gray-300">
                  <span className="font-medium">Recipient:</span> {award.recipient_name || 'N/A'}
                </div>
                <div className="text-sm text-gray-300">
                  <span className="font-medium">Agency:</span> {award.awarding_agency || 'N/A'}
                </div>
                <div className="text-sm text-gray-300">
                  <span className="font-medium">Total Obligations:</span> ${award.obligation ? award.obligation.toLocaleString() : 'N/A'}
                </div>
                <div className="text-sm text-gray-300">
                  <span className="font-medium">Reason:</span> {award.reason}
                </div>
                <div className="text-sm text-gray-300">
                  <span className="font-medium">Explanation:</span> {award.explanation}
                </div>
                <button
                  onClick={() => navigate(`/award/${award.generated_internal_id}`)}
                  className="text-blue-500 hover:underline text-sm"
                >
                  View Details
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
