import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AwardsTable from '../recipient/AwardsTable';
import { 
  fetchContractsAwardsByUEI, 
  fetchGrantsAwardsByUEI, 
  fetchLoansAwardsByUEI, 
  fetchIDVAwardsByUEI, 
  fetchOtherFinancialAssistanceAwardsByUEI, 
  fetchDirectPaymentsAwardsByUEI,
  fetchRecipientProfile  // Import fetchRecipientProfile
} from '../api';

export default function RecipientDetails() {
  const { uei } = useParams();
  const [awards, setAwards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recipientName, setRecipientName] = useState(""); // New state for recipient name

  useEffect(() => {
    async function getRecipientDetails() {
      setLoading(true);

      // Fetch recipient profile to get the name
      const profile = await fetchRecipientProfile(uei);
      if (profile) {
        setRecipientName(profile.name);
      } else {
        console.error("Failed to fetch recipient profile");
      }

      // Fetch all award types
      const allAwards = [];
      try {
        const [
          contractAwards, 
          grantAwards, 
          loanAwards, 
          idvAwards, 
          otherFinancialAssistanceAwards, 
          directPaymentsAwards
        ] = await Promise.all([
          fetchContractsAwardsByUEI(uei),
          fetchGrantsAwardsByUEI(uei),
          fetchLoansAwardsByUEI(uei),
          fetchIDVAwardsByUEI(uei),
          fetchOtherFinancialAssistanceAwardsByUEI(uei),
          fetchDirectPaymentsAwardsByUEI(uei),
        ]);

        allAwards.push(
          ...contractAwards,
          ...grantAwards,
          ...loanAwards,
          ...idvAwards,
          ...otherFinancialAssistanceAwards,
          ...directPaymentsAwards
        );

        setAwards(allAwards);
      } catch (error) {
        console.error("Failed to fetch awards data by UEI:", error);
      }

      setLoading(false);
    }

    if (uei) {
      getRecipientDetails();
    }
  }, [uei]);

  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Awards for {recipientName || `Recipient (UEI: ${uei})`}</h1>
      {loading ? (
        <p>Loading awards data...</p>
      ) : (
        <AwardsTable awards={awards} />
      )}
    </div>
  );
}
