import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import debounce from 'lodash.debounce';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';
import Stats from './Stats';

const ITEMS_PER_PAGE = 100;

export default function DetailedTable() {
  const [transactions, setTransactions] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [amountFilter, setAmountFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOption, setSortOption] = useState("date_new");
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState("");
  
  const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [recipientStats, setRecipientStats] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  useEffect(() => {
    fetchAgencies();
  }, []);

  String.prototype.toSentenceCase = function () {
    return this
      .toLowerCase()
      .replace(/(?:^|\.\s+)([a-z])/g, function(match, group1) {
        return match.replace(group1, group1.toUpperCase());
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, selectedAgencies, amountFilter, startDate, endDate, sortOption, keywords]);

  const fetchAgencies = async () => {
    const { data, error } = await supabase
      .from('transactions')
      .select('awarding_agency', { distinct: true });
    if (error) console.error('Error fetching agencies:', error);
    else setAgencyOptions(data.map(agency => ({ label: agency.awarding_agency, value: agency.awarding_agency })));
  };

  const fetchTransactions = async () => {
    let query = supabase
      .from('transactions')
      .select('action_date, awarding_agency, recipient_name, transaction_amount, transaction_description', { count: 'exact' })
      .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);

    switch (sortOption) {
      case "date_new":
        query = query.order('action_date', { ascending: false });
        break;
      case "date_old":
        query = query.order('action_date', { ascending: true });
        break;
      case "amount_high":
        query = query.order('transaction_amount', { ascending: false });
        break;
      case "amount_low":
        query = query.order('transaction_amount', { ascending: true });
        break;
      case "recipient_az":
        query = query.order('recipient_name', { ascending: true });
        break;
      case "recipient_za":
        query = query.order('recipient_name', { ascending: false });
        break;
      case "agency_az":
        query = query.order('awarding_agency', { ascending: true });
        break;
      case "agency_za":
        query = query.order('awarding_agency', { ascending: false });
        break;
      default:
        query = query.order('action_date', { ascending: false });
    }

    if (selectedAgencies.length > 0) {
      const selectedAgencyNames = selectedAgencies.map(agency => agency.value);
      query = query.in('awarding_agency', selectedAgencyNames);
    }

    if (amountFilter) {
      if (amountFilter === 'Large') query = query.gte('transaction_amount', 1000000000);
      else if (amountFilter === 'Medium') query = query.gte('transaction_amount', 500000000).lt('transaction_amount', 1000000000);
      else if (amountFilter === 'Small') query = query.lt('transaction_amount', 500000000);
    }

    if (startDate) query = query.gte('action_date', startDate.toISOString());
    if (endDate) query = query.lte('action_date', endDate.toISOString());

    if (keywords.length > 0) {
      query = query.or(keywords.map(keyword => `transaction_description.ilike.%${keyword}%`).join(','));
    }

    const { data, error, count } = await query;
    if (error) console.error('Error fetching transactions:', error);
    else {
      setTransactions(data);
      setTotalPages(Math.ceil(count / ITEMS_PER_PAGE));
    }
  };

  const handleAddKeyword = (event) => {
    if (event.key === ',' || event.key === 'Tab') {
      event.preventDefault();
      if (keywordInput.trim()) {
        setKeywords([...keywords, keywordInput.trim()]);
        setKeywordInput('');
      }
    }
  };

  const removeKeyword = (keywordToRemove) => {
    setKeywords(keywords.filter(keyword => keyword !== keywordToRemove));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const openRecipientModal = async (recipient) => {
    setSelectedRecipient(recipient);
    const { data, error } = await supabase
      .from('transactions')
      .select('transaction_amount')
      .eq('recipient_name', recipient);

    if (error) console.error('Error fetching recipient stats:', error);
    else {
      const transactionCount = data.length;
      const totalAmount = data.reduce((acc, curr) => acc + curr.transaction_amount, 0);
      setRecipientStats([
        { name: 'Transaction Count', value: transactionCount },
        { name: 'Total Amount', value: `$${totalAmount.toLocaleString()}` },
      ]);
      setIsRecipientModalOpen(true);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 text-white">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-white">Transactions</h1>
          <p className="mt-2 text-sm text-gray-400">
            Detailed government transaction data with server-side filtering and pagination.
          </p>
        </div>
      </div>

      {/* Filters */}
      <div className="mt-4 flex flex-wrap gap-4">
        <div className="flex-grow min-w-[200px] max-w-xs border border-gray-600 rounded bg-gray-800">
          <Select
            isMulti
            options={agencyOptions}
            value={selectedAgencies}
            onChange={setSelectedAgencies}
            placeholder="Filter by agency"
            className="text-sm bg-gray-800"
          />
        </div>

        <div className="flex items-center space-x-2 min-w-[200px] max-w-xs">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start date"
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm w-full"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End date"
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm w-full"
          />
        </div>

        <div className="flex-grow min-w-[150px] max-w-xs">
          <select
            value={amountFilter}
            onChange={(e) => setAmountFilter(e.target.value)}
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full text-sm"
          >
            <option value="">Filter by amount</option>
            <option value="Large">Large</option>
            <option value="Medium">Medium</option>
            <option value="Small">Small</option>
          </select>
        </div>

        <div className="flex-grow min-w-[150px] max-w-xs">
          <select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full text-sm"
          >
            <option value="date_new">Date: Newest to Oldest</option>
            <option value="date_old">Date: Oldest to Newest</option>
            <option value="amount_high">Amount: High to Low</option>
            <option value="amount_low">Amount: Low to High</option>
            <option value="recipient_az">Recipient: A-Z</option>
            <option value="recipient_za">Recipient: Z-A</option>
            <option value="agency_az">Agency: A-Z</option>
            <option value="agency_za">Agency: Z-A</option>
          </select>
        </div>
      </div>

      {/* Keyword Search */}
      <div className="flex flex-row items-center bg-gray-800 rounded p-2 space-x-2 mt-2 border border-gray-600">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="Enter keywords and press Tab, comma, or Enter"
          value={keywordInput}
          onChange={(e) => setKeywordInput(e.target.value)}
          onKeyDown={handleAddKeyword}
          className="flex-grow bg-transparent focus:outline-none text-white"
        />
        <div className="flex flex-wrap gap-1 mt-2 md:mt-0">
          {keywords.map((keyword, index) => (
            <div key={index} className="flex items-center px-2 py-1 bg-blue-400 text-white text-xs rounded">
              {keyword}
              <button onClick={() => removeKeyword(keyword)} className="ml-1">
                <XMarkIcon className="h-3 w-3" />
              </button>
            </div>
          ))}
        </div>
      </div>

      <Stats transactions={transactions} />

      {/* Table */}
      <div className="mt-2 overflow-hidden rounded-lg shadow">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700 bg-gray-800">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-400">Date</th>
                <th className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-400">Agency</th>
                <th className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-400">Recipient</th>
                <th className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-400">Amount</th>
                <th className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-400">Description</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {transactions.map((transaction, index) => (
                <tr key={index} className="hover:bg-gray-700 transition">
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{new Date(transaction.action_date).toLocaleDateString()}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{transaction.awarding_agency}</td>
                  <td
                    className="whitespace-nowrap px-3 py-4 text-sm text-blue-400 cursor-pointer"
                    onClick={() => openRecipientModal(transaction.recipient_name)}
                  >
                    {transaction.recipient_name ? transaction.recipient_name.slice(0, 30) : ""}...
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">${transaction.transaction_amount.toLocaleString()}</td>
                  <td
                    className="whitespace-nowrap px-3 py-4 text-sm text-blue-400 cursor-pointer"
                    onClick={() => {
                      setSelectedDescription(transaction.transaction_description);
                      setIsDescriptionModalOpen(true);
                    }}
                  >
                    {transaction.transaction_description ? transaction.transaction_description.slice(0, 50) + "..." : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="mt-4 flex items-center justify-between">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-gray-200 disabled:opacity-50"
        >
          Previous
        </button>
        <span className="text-sm font-semibold text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-gray-200 disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Recipient Modal */}
      <Dialog open={isRecipientModalOpen} onClose={() => setIsRecipientModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-md w-full rounded bg-gray-800 p-6 text-white">
            <Dialog.Title className="text-xl font-semibold">{selectedRecipient}</Dialog.Title>
            <div className="mt-4 grid grid-cols-1 gap-px bg-gray-700 sm:grid-cols-2 lg:grid-cols-4">
              {recipientStats.map((stat) => (
                <div key={stat.name} className="bg-gray-800 px-4 py-6 sm:px-6 lg:px-8">
                  <p className="text-sm font-medium text-gray-400">{stat.name}</p>
                  <p className="mt-2 text-4xl font-semibold text-white">{stat.value}</p>
                </div>
              ))}
            </div>
            <button
              onClick={() => setIsRecipientModalOpen(false)}
              className="mt-6 w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
            >
              Close
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Description Modal */}
      <Dialog open={isDescriptionModalOpen} onClose={() => setIsDescriptionModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-md w-full rounded bg-gray-800 p-6 text-white overflow-hidden max-h-[80vh] overflow-y-auto">
            <Dialog.Title className="text-xl font-semibold mb-4">Transaction Description</Dialog.Title>
            <div className="mt-2 max-h-64 overflow-y-auto text-gray-300">
              {selectedDescription.toSentenceCase()}
            </div>
            <button
              onClick={() => setIsDescriptionModalOpen(false)}
              className="mt-6 w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
            >
              Close
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
