import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import dawgLogo from '../assets/dawgsLogo.png';

export default function SignupPage() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [usernameError, setUsernameError] = useState(null); // New state for username validation
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Validate email format
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Check if username is available
  const checkUsernameAvailability = async () => {
    if (!username) return; // Avoid unnecessary requests for empty username
    const { data: existingUser } = await supabase
      .from('profiles')
      .select('id')
      .eq('username', username)
      .single();
    setUsernameError(existingUser ? 'Username is already taken' : null);
  };

  // Handle form submission
  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
  
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    if (usernameError) return; // Stop if username is already taken
  
    setLoading(true);
  
    // Sign up the user
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: 'https://govdawgs.com/login',
      },
    });
  
    if (authError) {
      setError(authError.message);
      setLoading(false);
      return;
    }
  
    const userId = authData.user.id;
  
    // Update the existing profile with the username and other information
    const { error: profileError } = await supabase
      .from('profiles')
      .update({
        username,
        email,
      })
      .eq('id', userId); // Update the profile where the id matches the user's id
  
    if (profileError) {
      setError(profileError.message);
      setLoading(false);
      return;
    }
  
    navigate('/login'); // Redirect to login page after successful signup
    setLoading(false);
  };
  

  return (
    <div className="flex min-h-screen flex-1 flex-col px-6 py-12 bg-gray-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          src={dawgLogo}
          alt="Gov-Dawgs Logo"
          className="mx-auto h-14 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold tracking-tight text-white">
          Create an Account
        </h2>
        <p className="mt-3 text-center text-md tracking-tight text-white">You will add profile details after registering.</p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSignup} className="space-y-6">
          {error && <p className="text-center text-red-500">{error}</p>}

          <div>
            <label htmlFor="username" className="block text-sm font-medium text-white">
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="text"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={checkUsernameAvailability} // Check username availability on blur
                autoComplete="username"
                className={`block w-full rounded-md border-0 bg-gray-700 py-2 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset ${
                  usernameError ? 'focus:ring-red-500' : 'focus:ring-blue-500'
                } sm:text-sm`}
              />
              {usernameError && <p className="mt-2 text-sm text-red-500">{usernameError}</p>}
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                className="block w-full rounded-md border-0 bg-gray-700 py-2 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-white">
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                className="block w-full rounded-md border-0 bg-gray-700 py-2 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-white">
              Confirm Password
            </label>
            <div className="mt-2">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="new-password"
                className="block w-full rounded-md border-0 bg-gray-700 py-2 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm"
              />
            </div>
            {confirmPassword && password !== confirmPassword && (
              <p className="mt-2 text-sm text-red-500">Passwords do not match.</p>
            )}
          </div>

          <div>
            <button
              type="submit"
              disabled={loading || !validateEmail(email) || password !== confirmPassword || usernameError}
              className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 transition duration-150 ease-in-out ${
                loading || !validateEmail(email) || password !== confirmPassword || usernameError
                  ? 'cursor-not-allowed opacity-50'
                  : ''
              }`}
            >
              {loading ? 'Creating Account...' : 'Sign Up'}
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-400">
          Already have an account?{' '}
          <a href="/login" className="font-semibold text-blue-400 hover:text-blue-300">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
}
