// AwardVisualization.js

import React from 'react';
import { Dialog } from '@headlessui/react';
import { Bar, Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/20/solid';

// Import Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default function AwardVisualization({ isOpen, onClose, filteredResults }) {
  // Aggregate data for the bar chart
  const aggregateDataByAgency = () => {
    const data = {};
    filteredResults.forEach((result) => {
      const agency = result.agency || 'Unknown Agency';
      const amount = Number(result.awardAmount || 0);
      data[agency] = (data[agency] || 0) + amount;
    });
    return data;
  };

  // Prepare bar chart data
  const chartData = () => {
    const aggregatedData = aggregateDataByAgency();
    return {
      labels: Object.keys(aggregatedData),
      datasets: [
        {
          label: 'Sum of Award Amounts by Agency',
          data: Object.values(aggregatedData),
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  // Aggregate data for the line chart (spending over time)
  const spendingOverTimeData = () => {
    const data = {};
    filteredResults.forEach((result) => {
      const date = result.startDate ? result.startDate.slice(0, 7) : 'Unknown Date'; // Group by month (YYYY-MM)
      const amount = Number(result.awardAmount || 0);
      data[date] = (data[date] || 0) + amount;
    });
    const sortedDates = Object.keys(data).sort(); // Sort dates
    return {
      labels: sortedDates,
      datasets: [
        {
          label: 'Total Spending Over Time',
          data: sortedDates.map((date) => data[date]),
          fill: false,
          backgroundColor: 'rgba(75, 192, 192, 0.4)',
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1, // Smooth line
        },
      ],
    };
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="relative max-w-4xl w-full max-h-[80vh] overflow-y-auto rounded bg-gray-800 p-6 text-white">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-700"
            aria-label="Close"
          >
            <XMarkIcon className="h-6 w-6 text-gray-300" />
          </button>

          <Dialog.Title className="text-xl font-semibold mb-4">Award Visualizations</Dialog.Title>

          {/* Bar Chart: Sum of Award Amounts by Agency */}
          <div className="h-96 mb-8">
            <h2 className="text-lg font-semibold mb-4">Award Amounts by Agency</h2>
            <Bar data={chartData()} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>

          {/* Line Chart: Spending Over Time */}
          <div className="h-96 mt-20 mb-10">
            <h2 className="text-lg font-semibold mb-4">Spending Over Time</h2>
            <Line data={spendingOverTimeData()} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

AwardVisualization.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filteredResults: PropTypes.array.isRequired,
};
