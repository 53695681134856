import React, { useEffect, useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Stats({ transactions }) {
  const [stats, setStats] = useState([
    { name: 'Total Transactions', value: '$0.00' },
    { name: 'Max Transaction', value: '$0.00' },
    { name: 'Average Transaction', value: '$0.00' },
    { name: 'Transaction Count', value: '0' }
  ]);

  useEffect(() => {
    if (transactions.length > 0) {
      const total = transactions.reduce((sum, transaction) => sum + transaction.transaction_amount, 0);
      const max = Math.max(...transactions.map(transaction => transaction.transaction_amount));
      const average = total / transactions.length;
      const count = transactions.length;

      setStats([
        { name: 'Total Transactions', value: `$${total.toLocaleString()}` },
        { name: 'Max Transaction', value: `$${max.toLocaleString()}` },
        { name: 'Average Transaction', value: `$${average.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` },
        { name: 'Transaction Count', value: count.toLocaleString() }
      ]);
    }
  }, [transactions]);

  return (
    <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 border my-2 rounded-md">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-5 sm:px-3 xl:px-4"
        >
          <dt className="text-sm font-medium text-gray-500">{stat.name}</dt>
          <dd className="w-full flex-none text-2xl font-medium tracking-tight text-gray-900">{stat.value}</dd>
        </div>
      ))}
    </dl>
  );
}
