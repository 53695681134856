const API_BASE_URL = 'https://api.usaspending.gov/api/v2';

// Define groups of award type codes
const AWARD_TYPE_CODE_GROUPS = {
  contracts: ['A', 'B', 'C', 'D'],
  loans: ['07', '08'],
  idvs: ['IDV_A', 'IDV_B', 'IDV_B_A', 'IDV_B_B', 'IDV_B_C', 'IDV_C', 'IDV_D', 'IDV_E'],
  grants: ['02', '03', '04', '05'],
  other_financial_assistance: ['06', '10'],
  direct_payments: ['09', '11', '-1']
};

/**
 * Fetch awards for each specific group by UEI.
 */
export async function fetchContractsAwardsByUEI(uei) {
  return fetchRecipientAwardsByUEI(uei, AWARD_TYPE_CODE_GROUPS.contracts);
}

export async function fetchLoansAwardsByUEI(uei) {
  return fetchRecipientAwardsByUEI(uei, AWARD_TYPE_CODE_GROUPS.loans);
}

export async function fetchIDVAwardsByUEI(uei) {
  return fetchRecipientAwardsByUEI(uei, AWARD_TYPE_CODE_GROUPS.idvs);
}

export async function fetchGrantsAwardsByUEI(uei) {
  return fetchRecipientAwardsByUEI(uei, AWARD_TYPE_CODE_GROUPS.grants);
}

export async function fetchOtherFinancialAssistanceAwardsByUEI(uei) {
  return fetchRecipientAwardsByUEI(uei, AWARD_TYPE_CODE_GROUPS.other_financial_assistance);
}

export async function fetchDirectPaymentsAwardsByUEI(uei) {
  return fetchRecipientAwardsByUEI(uei, AWARD_TYPE_CODE_GROUPS.direct_payments);
}

/**
 * Fetch awards for a specific group of award type codes.
 */
export async function fetchRecipientAwardsByUEI(uei, awardTypeCodes, page = 1, limit = 50, sort = "Award Amount", order = "desc") {
  const body = JSON.stringify({
    filters: {
      keywords: [uei],
      award_type_codes: awardTypeCodes
    },
    fields : [
        "Award ID",
        "Recipient Name",
        "Recipient DUNS Number",
        "recipient_id",
        "Awarding Agency",
        "Awarding Agency Code",
        "Awarding Sub Agency",
        "Awarding Sub Agency Code",
        "Funding Agency",
        "Funding Agency Code",
        "Funding Sub Agency",
        "Funding Sub Agency Code",
        "Place of Performance City Code",
        "Place of Performance State Code",
        "Place of Performance Country Code",
        "Place of Performance Zip5",
        "Description",
        "Last Modified Date",
        "Base Obligation Date",
        "prime_award_recipient_id",
        "generated_internal_id",
        "def_codes",
        "COVID-19 Obligations",
        "COVID-19 Outlays",
        "Infrastructure Obligations",
        "Infrastructure Outlays",
        "Start Date",
        "End Date",
        "Award Amount",
        "Total Outlays",
        "Contract Award Type",
        "Last Date to Order",
        "Issued Date",
        "Loan Value",
        "Subsidy Cost",
        "SAI Number",
        "CFDA Number",
        "Period of Performance Current End Date",
        "Period of Performance Start Date",
        "COVID-19 Obligations",
        "COVID-19 Outlays",
        "Infrastructure Obligations",
        "Infrastructure Outlays",
        "Award Type"
    ],
        page: page,
    limit: limit,
    sort: sort,
    order: order
  });

  try {
    const response = await fetch(`${API_BASE_URL}/search/spending_by_award/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body
    });

    if (!response.ok) {
      console.error('Error fetching recipient awards:', response.statusText);
      return [];
    }

    const data = await response.json();
    return data.results || [];
  } catch (error) {
    console.error('Error in fetchRecipientAwardsByUEI:', error);
    return [];
  }
}

/**
 * Fetch all awards for a recipient by calling each group function.
 */
export async function fetchAllRecipientAwardsByUEI(uei) {
  const allAwards = [];

  // Fetch awards for each group of award type codes in parallel
  const fetchPromises = [
    fetchContractsAwardsByUEI(uei),
    fetchLoansAwardsByUEI(uei),
    fetchIDVAwardsByUEI(uei),
    fetchGrantsAwardsByUEI(uei),
    fetchOtherFinancialAssistanceAwardsByUEI(uei),
    fetchDirectPaymentsAwardsByUEI(uei)
  ];

  // Wait for all fetch requests to complete and combine results
  const results = await Promise.all(fetchPromises);
  results.forEach((groupAwards) => allAwards.push(...groupAwards));

  return allAwards;
}

/**
 * Fetch the UEI based on the recipient name.
 */
export async function fetchRecipientIdByName(recipientName) {
  console.log(`Searching for recipient UEI by name: ${recipientName}`);

  try {
    const response = await fetch(`${API_BASE_URL}/recipient/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        keyword: recipientName,
        order: "desc",
        sort: "amount",
        page: 1,
        limit: 1,
      })
    });

    if (!response.ok) {
      console.error('Error fetching recipient UEI:', response.status, response.statusText);
      return null;
    }

    const data = await response.json();
    console.log(`Received data for UEI search:`, data);

    if (data.results && data.results.length > 0) {
      const uei = data.results[0].uei;
      console.log(`Found UEI: ${uei}`);
      return uei;
    } else {
      console.warn('No recipient found for the given name:', recipientName);
      return null;
    }
  } catch (error) {
    console.error('Error in fetchRecipientIdByName:', error);
    return null;
  }
}


/**
 * Fetch detailed profile information for a recipient by UEI.
 * @param {string} uei - The Unique Entity Identifier for the recipient.
 * @returns {object|null} - The recipient profile data or null if an error occurs.
 */
export async function fetchRecipientProfile(uei) {
    console.log(`Fetching profile for recipient UEI: ${uei}`);
  
    try {
      const response = await fetch(`${API_BASE_URL}/recipient/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order: "desc",
          sort: "amount",
          page: 1,
          limit: 50,
          keyword: uei,
          award_type: "all"
        })
      });
  
      if (!response.ok) {
        console.error('Error fetching recipient profile:', response.status, response.statusText);
        return null;
      }
  
      const data = await response.json();
      console.log(`Successfully fetched profile for UEI: ${uei}`, data);
  
      // Extract and return the recipient information if available
      if (data.results && data.results.length > 0) {
        return data.results[0];  // Return the first matching recipient
      } else {
        console.warn('No recipient found for the given UEI:', uei);
        return null;
      }
    } catch (error) {
      console.error('Error in fetchRecipientProfile:', error);
      return null;
    }
  }
