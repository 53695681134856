import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Dialog } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { fetchRecipientIdByName } from '../api';
import debounce from 'lodash.debounce';

const ITEMS_PER_PAGE = 20;

const CATEGORY_COLORS = {
  "Equity and Inclusion": "bg-blue-100 text-blue-700",
  "Environmental and Climate Initiatives": "bg-green-100 text-green-700",
  "Global and Foreign Relations": "bg-purple-100 text-purple-700",
  "Middle East": "bg-yellow-100 text-yellow-700",
  "Defense and Military": "bg-red-100 text-red-700",
  "Border Security and Immigration": "bg-teal-100 text-teal-700",
  "Economic and Trade Relations": "bg-indigo-100 text-indigo-700",
  "Health and Humanitarian Aid": "bg-pink-100 text-pink-700",
  "Miscellaneous Global Affairs": "bg-gray-100 text-gray-700",
};

export default function Feed() {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [uei, setRecipientUEI] = useState(null);
  const [recipientLoading, setRecipientLoading] = useState(false);

  const [sortField, setSortField] = useState("action_date");
  const [sortOrder, setSortOrder] = useState("desc");

  const [filterCategory, setFilterCategory] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, keywords, sortField, sortOrder, filterCategory]);

  const fetchTransactions = async () => {
    setLoading(true);

    let query = supabase
      .from('transactions')
      .select('id, action_date, awarding_agency, recipient_name, transaction_amount, transaction_description, award_id, generated_internal_id', { count: 'exact' })
      .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1)
      .order(sortField, { ascending: sortOrder === "asc" });

    if (keywords.length > 0) {
      query = query.or(keywords.map(keyword => `transaction_description.ilike.%${keyword}%`).join(','));
    }

    if (filterCategory) {
      const categoryKeywords = getCategoryKeywords(filterCategory);
      query = query.or(categoryKeywords.map(keyword => `transaction_description.ilike.%${keyword}%`).join(','));
    }

    const { data, error, count } = await query;
    if (error) {
      console.error('Error fetching transactions:', error);
    } else {
      if (currentPage === 1) {
        setTransactions(data);
      } else {
        setTransactions((prev) => [...prev, ...data]);
      }
      setTotalPages(Math.ceil(count / ITEMS_PER_PAGE));
    }
    setLoading(false);
  };

  const handleAddKeyword = (event) => {
    if (event.key === ',' || event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      if (keywordInput.trim()) {
        setKeywords([...keywords, keywordInput.trim()]);
        setKeywordInput('');
        setCurrentPage(1);
        setTransactions([]);
      }
    }
  };

  const removeKeyword = (keywordToRemove) => {
    setKeywords(keywords.filter(keyword => keyword !== keywordToRemove));
    setCurrentPage(1);
    setTransactions([]);
  };

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const openModal = async (transaction) => {
    setSelectedTransaction(transaction);
    setIsDescriptionExpanded(false); // Reset the expanded state for each transaction
    setIsOpen(true);
    setRecipientLoading(true);
    setRecipientUEI(null);

    const uei = await fetchRecipientIdByName(transaction.recipient_name);
    if (uei) {
      setRecipientUEI(uei);
    } else {
      console.warn('UEI not found for:', transaction.recipient_name);
    }
    setRecipientLoading(false);
  };

  const toggleDescription = () => setIsDescriptionExpanded(!isDescriptionExpanded);

  const closeModal = () => {
    setIsOpen(false);
    setSelectedTransaction(null);
    setRecipientUEI(null);
  };

  const handleViewRecipient = () => {
    if (uei) {
      navigate(`/recipients/${uei}`);
      closeModal();
    }
  };

  const handleOpenAward = () => {
    if (selectedTransaction?.generated_internal_id) {
      navigate(`/award/${selectedTransaction.generated_internal_id}`);
      closeModal();
    }
  };

  const handleSortChange = (field, order) => {
    setSortField(field);
    setSortOrder(order);
    setCurrentPage(1);
    setTransactions([]);
  };

  const handleFilterChange = (category) => {
    setFilterCategory(category);
    setCurrentPage(1);
    setTransactions([]);
  };

  const getCategoryKeywords = (category) => {
    const categoryKeywords = {
      "Equity and Inclusion": ["inflation reduction act", "equity", "diversity", "inclusion", "racial", "minority", "bias", "gender", "justice", "DEI", "LGBTQ", "social justice", "feminism"],
      "Environmental and Climate Initiatives": ["climate", "green", "environment", "sustainability", "carbon", "clean energy", "solar", "biodiversity", "global warming", "renewable", "pollution"],
      "Global and Foreign Relations": ["foreign", "international", "global", "diplomacy", "UN", "United Nations", "peacekeeping", "embassy", "foreign relations"],
      "Middle East": ["middle east", "afghanistan", "iran", "iraq", "syria", "yemen", "saudi arabia", "israel", "palestine", "lebanon"],
      "Defense and Military": ["war", "military aid", "defense", "arms", "weapons", "NATO", "military spending", "counter-terrorism", "strategic interests"],
      "Border Security and Immigration": ["illegal immigration", "border security", "immigration reform", "asylum", "refugees", "deportation", "border patrol", "sanctuary", "immigration policy"],
      "Economic and Trade Relations": ["foreign trade", "tariffs", "import", "export", "international trade", "global market", "foreign direct investment", "outsourcing"],
      "Health and Humanitarian Aid": ["foreign aid", "global health", "pandemic response", "vaccine distribution", "HIV/AIDS", "disaster relief", "epidemic", "humanitarian aid"],
      "Miscellaneous Global Affairs": ["cybersecurity", "human rights", "cultural exchange", "peace process", "arms control", "foreign policy", "nation building"]
    };
    return categoryKeywords[category] || [];
  };

  const getTransactionCategories = (description) => {
    const matchedCategories = [];
    Object.keys(CATEGORY_COLORS).forEach(category => {
      const keywords = getCategoryKeywords(category);
      if (keywords.some(keyword => description.toLowerCase().includes(keyword))) {
        matchedCategories.push(category);
      }
    });
    return matchedCategories;
  };

  return (
    <div className="max-w-2xl mx-auto p-4 space-y-4 bg-gray-800 text-white">
      {/* Keyword Search */}
      <div className="flex flex-row md:flex-row items-center bg-gray-700 rounded-lg p-2 space-x-2">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="Enter keywords and press Tab, comma, or Enter"
          value={keywordInput}
          onChange={(e) => setKeywordInput(e.target.value)}
          onKeyDown={handleAddKeyword}
          className="flex-grow bg-transparent focus:outline-none text-white"
        />
        <div className="flex flex-wrap gap-1 mt-2 md:mt-0">
          {keywords.map((keyword, index) => (
            <div key={index} className="flex items-center px-2 py-1 bg-blue-400 text-white text-xs rounded">
              {keyword}
              <button onClick={() => removeKeyword(keyword)} className="ml-1">
                <XMarkIcon className="h-3 w-3" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Sorting and Filtering Dropdowns */}
      <div className="flex flex-col md:flex-row justify-between gap-2 mt-2">
        <select
          onChange={(e) => {
            const [field, order] = e.target.value.split("-");
            handleSortChange(field, order);
          }}
          className="p-2 border border-gray-600 rounded bg-gray-700 text-white w-full md:w-auto"
          defaultValue="action_date-desc"
        >
          <option value="action_date-desc">Date: Newest to Oldest</option>
          <option value="action_date-asc">Date: Oldest to Newest</option>
          <option value="transaction_amount-desc">Amount: High to Low</option>
          <option value="transaction_amount-asc">Amount: Low to High</option>
        </select>

        <select
          onChange={(e) => handleFilterChange(e.target.value)}
          className="p-2 border border-gray-600 rounded bg-gray-700 text-white w-full md:w-auto"
          defaultValue=""
        >
          <option value="">All Categories</option>
          {Object.keys(CATEGORY_COLORS).map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      {/* Transaction Feed */}
      <div className="space-y-6">
        {transactions.map((transaction) => {
          const categories = getTransactionCategories(transaction.transaction_description || "");
          return (
            <div
              key={transaction.id}
              className="p-4 bg-gray-700  mx-auto rounded-lg shadow-md hover:bg-gray-600 transition-all cursor-pointer"
              onClick={() => openModal(transaction)}
            >
              <div className="flex justify-between items-center">
                <h3 className="font-bold text-white">{transaction.awarding_agency}</h3>
                <span className="text-xs text-gray-400">{transaction.action_date}</span>
              </div>
              <p className="text-white mt-2 font-medium">${transaction.transaction_amount.toLocaleString()}</p>
              <p className="text-gray-300 text-sm mt-1">{transaction.recipient_name}</p>
              <div className="mt-2 flex flex-wrap gap-2">
                {categories.map((category) => (
                  <span
                    key={category}
                    className={`px-2 py-1 text-xs font-medium rounded-full ${CATEGORY_COLORS[category]}`}
                  >
                    {category}
                  </span>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {/* Modal for transaction details */}
      <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <Dialog.Panel className="w-full max-w-lg p-6 bg-gray-700 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto">
            <Dialog.Title className="text-lg font-semibold text-white">Transaction Details</Dialog.Title>
            {selectedTransaction && (
              <>
                <p className="mt-2 text-gray-300"><strong>Recipient:</strong> {selectedTransaction.recipient_name}</p>
                <p className="text-gray-300"><strong>Amount:</strong> ${selectedTransaction.transaction_amount.toLocaleString()}</p>
                <p className="text-gray-300"><strong>Agency:</strong> {selectedTransaction.awarding_agency}</p>
                <p className="text-gray-300"><strong>Date:</strong> {selectedTransaction.action_date}</p>
                <p className="text-gray-300">
                  <strong>Description:</strong> {isDescriptionExpanded ? selectedTransaction.transaction_description : `${selectedTransaction.transaction_description?.substring(0, 200)}...`}
                  {selectedTransaction.transaction_description?.length > 200 && (
                    <button onClick={toggleDescription} className="ml-2 text-indigo-300 hover:underline">
                      {isDescriptionExpanded ? 'Read Less' : 'Read More'}
                    </button>
                  )}
                </p>

                <div className="mt-6 flex justify-end space-x-4">
                  <button
                    onClick={handleViewRecipient}
                    disabled={!uei || recipientLoading}
                    className={`px-4 py-2 rounded-md font-semibold text-white ${recipientLoading ? 'bg-gray-500' : 'bg-blue-600 hover:bg-blue-500'} transition`}
                  >
                    {recipientLoading ? 'Loading...' : 'View Recipient'}
                  </button>
                  <button
                    onClick={handleOpenAward}
                    className="px-4 py-2 rounded-md font-semibold text-white bg-green-600 hover:bg-green-500 transition"
                  >
                    Open Award
                  </button>
                </div>
              </>
            )}
            <button
              onClick={closeModal}
              className="mt-4 w-full px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Close
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
