import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';

export default function ProfilePage() {
  const [profile, setProfile] = useState({
    username: '',
    about: '',
    full_name: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      // Fetch the authenticated user
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) {
        console.error('Error fetching user:', authError);
        return;
      }
      console.log('Authenticated user:', user);

      if (user) {
        // Fetch the profile data from the 'profiles' table
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('username, about, full_name, email')
          .eq('id', user.id)
          .single();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          return;
        }
        
        console.log('Profile data from Supabase:', profileData);

        // Populate profile state with fetched data
        setProfile({
          username: profileData.username || '',
          about: profileData.about || '',
          full_name: profileData.full_name || '',
          email: profileData.email || user.email, // fallback to auth user email
        });
      }
    };

    fetchProfile();
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    const { data: { user } } = await supabase.auth.getUser();

    // Update the existing profile record in the 'profiles' table
    const { error } = await supabase
      .from('profiles')
      .update({
        username: profile.username,
        about: profile.about,
        full_name: profile.full_name,
      })
      .eq('id', user.id);

    setLoading(false);

    if (error) {
      setMessage({ type: 'error', content: error.message });
    } else {
      setMessage({ type: 'success', content: 'Profile updated successfully!' });
    }
  };

  const handlePasswordReset = async () => {
    setLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(profile.email);
    setLoading(false);
    setMessage({
      type: error ? 'error' : 'success',
      content: error ? error.message : 'Password reset link sent!',
    });
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col px-6 py-12 bg-gray-900 text-white">
      <form onSubmit={handleUpdateProfile} className="space-y-12">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold text-white">Profile</h2>
          <p className="mt-1 text-sm text-gray-400">
            This information will be displayed publicly, so be careful what you share.
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="username" className="block text-sm font-medium text-white">
                Username
              </label>
              <div className="mt-2 flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                <input
                  id="username"
                  type="text"
                  value={profile.username}
                  onChange={(e) => setProfile({ ...profile, username: e.target.value })}
                  className="flex-1 border-0 bg-transparent py-1.5 pl-3 text-white focus:ring-0 sm:text-sm"
                  placeholder="janesmith"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium text-white">
                About
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  rows={3}
                  value={profile.about}
                  onChange={(e) => setProfile({ ...profile, about: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                  placeholder="Write a few sentences about yourself."
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="full_name" className="block text-sm font-medium text-white">
                Full Name
              </label>
              <div className="mt-2">
                <input
                  id="full_name"
                  type="text"
                  value={profile.full_name}
                  onChange={(e) => setProfile({ ...profile, full_name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="email" className="block text-sm font-medium text-white">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  type="email"
                  value={profile.email}
                  readOnly
                  className="block w-full rounded-md border-0 bg-gray-700 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="photo" className="block text-sm font-medium text-white">
                Profile Picture
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                <UserCircleIcon className="h-12 w-12 text-gray-500" />
                <button
                  type="button"
                  className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold text-white">Password</h2>
          <p className="mt-1 text-sm text-gray-400">Reset your password by sending a reset link to your email.</p>

          <button
            type="button"
            onClick={handlePasswordReset}
            disabled={loading}
            className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {loading ? 'Sending Reset Link...' : 'Send Password Reset Link'}
          </button>
        </div>

        {message && (
          <p className={`mt-4 text-center ${message.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>
            {message.content}
          </p>
        )}

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold text-white">
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
