import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

export default function FlaggedFeed() {
  const [flaggedAwards, setFlaggedAwards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
      } else if (user) {
        setUserId(user.id);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchFlaggedFeed = async () => {
      setLoading(true);

      const { data: flaggedData, error } = await supabase
        .from('flagged_awards')
        .select('generated_internal_id, reason, explanation, description, obligation, recipient_name, awarding_agency, created_at, profiles(username)')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching flagged feed:', error);
        setLoading(false);
        return;
      }

      const uniqueAwards = [...new Map(flaggedData.map(item => [item.generated_internal_id, item])).values()];
      const awardCounts = await Promise.all(uniqueAwards.map(async (award) => {
        const { count, error: countError } = await supabase
          .from('flagged_awards')
          .select('*', { count: 'exact', head: true })
          .eq('generated_internal_id', award.generated_internal_id);

        if (countError) {
          console.error('Error counting flags for award:', countError);
          return { ...award, flag_count: 0, isFlagged: false };
        }

        const { data: userFlag } = await supabase
          .from('flagged_awards')
          .select('id')
          .eq('generated_internal_id', award.generated_internal_id)
          .eq('user_id', userId)
          .single();

        return {
          ...award,
          flag_count: count,
          isFlagged: !!userFlag,
        };
      }));

      setFlaggedAwards(awardCounts);
      setLoading(false);
    };

    fetchFlaggedFeed();
  }, [userId]);

  const handleUpvote = async (generated_internal_id) => {
    if (!userId) {
      alert('You need to be logged in to upvote.');
      return;
    }

    const { data: existingFlag } = await supabase
      .from('flagged_awards')
      .select('id')
      .eq('user_id', userId)
      .eq('generated_internal_id', generated_internal_id)
      .single();

    if (existingFlag) {
      alert('You have already flagged this award.');
      return;
    }

    const { error: insertError } = await supabase
      .from('flagged_awards')
      .insert([{ user_id: userId, generated_internal_id }]);

    if (insertError) {
      console.error('Error upvoting the award:', insertError);
    } else {
      setFlaggedAwards((prevAwards) =>
        prevAwards.map((award) =>
          award.generated_internal_id === generated_internal_id
            ? { ...award, flag_count: award.flag_count + 1, isFlagged: true }
            : award
        )
      );
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="container mx-auto p-4 space-y-4">
      <h1 className="text-2xl font-semibold text-white">Latest Flagged Awards</h1>
      {flaggedAwards.length === 0 ? (
        <p className="text-gray-400">No awards have been flagged yet.</p>
      ) : (
        <div className="space-y-4">
          {flaggedAwards.map((award) => (
            <div
              key={award.generated_internal_id}
              className="max-w-xl mx-auto bg-gray-800 rounded-lg p-4 shadow-lg border border-gray-700 space-y-2"
            >
              {/* Username and timestamp */}
              <div className="flex items-center justify-between text-gray-300">
                <span className="font-medium text-sm">@{award.profiles?.username || 'Unknown User'}</span>
                <span className="text-xs text-gray-500">{new Date(award.created_at).toLocaleString()}</span>
              </div>

              {/* Description */}
              <div className="text-gray-200 text-base leading-tight">
                {award.description ? `${award.description.slice(0, 140)}...` : 'No description available.'}
              </div>

              {/* Obligation */}
              <div className="text-gray-400 text-sm">
                <span className="font-medium">Amount:</span> ${award.obligation ? award.obligation.toLocaleString() : 'N/A'}
              </div>

              {/* Recipient and agency */}
              {award.recipient_name && (
                <div className="text-sm text-gray-400">
                  <span className="font-medium">Recipient:</span> {award.recipient_name}
                </div>
              )}
              {award.awarding_agency && (
                <div className="text-sm text-gray-400">
                  <span className="font-medium">Agency:</span> {award.awarding_agency}
                </div>
              )}

              {/* Reason for flagging */}
              <div className="text-gray-300 text-sm">
                <span className="font-medium">Reason:</span> {award.reason}
              </div>

              {/* Upvote button and flag count */}
              <div className="flex items-center justify-between mt-4">
                <button
                  onClick={() => !award.isFlagged && handleUpvote(award.generated_internal_id)}
                  disabled={award.isFlagged}
                  className={`flex items-center rounded-full px-3 py-1 text-xs font-medium ring-1 ring-inset ${
                    award.isFlagged
                      ? 'bg-red-400/10 text-red-300 ring-red-400/30 cursor-not-allowed'
                      : 'bg-red-500/10 text-red-300 ring-red-500/30 hover:bg-red-500/20'
                  }`}
                >
                  {award.isFlagged ? 'Already Flagged' : 'Flag'}
                </button>
                <span className="text-sm text-gray-300">{award.flag_count} {award.flag_count === 1 ? 'Flag' : 'Flags'}</span>
                <button
                  onClick={() => navigate(`/award/${award.generated_internal_id}`)}
                  className="text-blue-500 hover:underline text-sm"
                >
                  View Award Details
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
